@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Aneliza";
  src: url("/public/fonts/Aneliza-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Aneliza";
  src: url("/public/fonts/Aneliza-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Aneliza";
  src: url("/public/fonts/Aneliza-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: #bbcced;
    --ring: 240 10% 3.9%;
    --primary: #0b00be;
    --destructive: #f72585;
    --muted: #e3eaf7;

    --blue-200: "#BBCCED";
    --gray-100: #858585;

    --radius: 0.5rem;
    --black: #2f2f2f;
    --white: #fdfdfd;
  }

  .dark {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: #bbcced;
    --ring: 240 10% 3.9%;
    --primary: #0b00be;
    --destructive: #f72585;

    --blue-200: "#BBCCED";

    --radius: 0.5rem;
    --black: #2f2f2f;
    --white: #fdfdfd;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }

  /* reset input type number default control buttons*/
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* design typography */
  .h1 {
    @apply font-aneliza font-semibold text-title-52 !important;
  }

  .h2 {
    @apply font-aneliza font-semibold text-title-44 !important;
  }

  .h3 {
    @apply font-aneliza font-bold text-title-36 !important;
  }

  .h4 {
    @apply font-aneliza font-semibold text-title-32 !important;
  }

  .h5 {
    @apply font-aneliza font-bold text-title-22 !important;
  }

  .h6 {
    @apply font-aneliza font-extrabold text-title-18 !important;
  }

  .b1 {
    @apply font-aneliza font-bold text-button-title-18 !important;
  }

  .b2 {
    @apply font-aneliza font-semibold text-button-title-16 !important;
  }

  .b3 {
    @apply font-aneliza font-semibold text-button-title-14 !important;
  }

  .tt {
    @apply font-aneliza font-normal text-text-title-18 !important;
  }

  .ft1 {
    @apply font-aneliza font-normal text-field-title-12 !important;
  }

  .ct {
    @apply font-aneliza font-normal text-caption-title-16 !important;
  }

  .ct2 {
    @apply font-aneliza font-medium text-caption-title-20 !important;
  }

  .description {
    @apply font-aneliza font-bold text-title-32 !important;
  }

  .background-image {
    background: url("./images/background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .content-padding-64 {
    @apply px-64 max-lg:px-4;
  }

  .content-padding-56 {
    @apply px-56 max-lg:px-2;
  }

  .gradient-orange-stops {
    --tw-gradient-stops: #ff7c00 0.03%, #ff7900 19.81%, #ff8500 46.35%, #ff9100 73.42%, #ffb800 94.75%;
  }

  .gradient-blue-stops {
    --tw-gradient-stops: #0b00be 0.09%, #1041d2 28.69%, #157de4 78.62%;
  }

  .gradient-cian-stops {
    --tw-gradient-stops: #0d987f -4.12%, #13a98e 30.49%, #48ccb3 83.05%, rgba(72, 204, 179, 0) 100%;
  }

  .gradient-blue-stops-card {
    --tw-gradient-stops: #2d0ca6 -3.09%, #1856f7 46.61%, rgba(113, 151, 248, 0.562629) 78.66%,
      rgba(169, 169, 169, 0) 101.06%;
  }
}